import React from 'react'

import {
  LAUNCH_ROOT_URL,
  LAUNCH_BASE_URLS,
  SALES_ANALYTICS_URL,
  SALES_ANALYTICS_URLS,
  INVENTORY_URLS,
  MARKET_INSIGHTS_URLS,
  LISTING_BUILDER_BASE,
  PRODUCT_INSIGHTS_URL,
  SHARE_OF_VOICE_URL,
  SALES_ESTIMATOR_URL,
  LISTING_GRADER_BASE,
  PRODUCT_RESEARCH_URLS,
  RANK_TRACKER_V2_BASE,
  SUPPLIER_URLS,
  KEYWORD_LISTS_URL,
  KEYWORD_SCOUT_URL,
  ACADEMY_URL,
  EXTENSION_URL,
  ADMIN_URL,
  DASHBOARD_URL,
  ADVERTISING_URL,
  ADVERTISING_URLS,
  PAGE_NAMES,
  PRODUCT_COSTS_AND_SETTINGS_URL,
  INVENTORY_REIMBURSEMENTS_URL,
  DEVELOPER_URL,
  COMPETITIVE_INTELLIGENCE_URL
} from 'constants/routes'
import { COBALT_APP_TYPE, DEFAULT_APP_TYPE } from 'constants/themes'
import { Features } from 'constants/feature_names'
import { PERMISSION_KEYS } from 'constants/account/permissions'
import { NAVIGATION_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/navigation'

import { isBeforeToday } from 'helpers/date'
import { isFeatureEnabled } from './features'
import { Icon } from '@junglescout/edna'

export const buildCNMenuItems = ({ t, flagData, featureLimits, isCobalt }) => {
  function renderBadge(end, text) {
    return isBeforeToday(end) ? null : text
  }

  const pageNames = PAGE_NAMES(t)

  const isNewShareOfVoice =
    isFeatureEnabled(Features.KEYWORDS_SHARE_OF_VOICE, flagData) &&
    featureLimits?.allowedMarketplaces?.length
      ? renderBadge('2024-02-17', t('generic:BetaTag', 'BETA'))
      : undefined

  return [
    {
      name: t('sidebar:marketInsights.header', 'Market Insights'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.MarketInsights,
      icon: <Icon name="NAV_MARKET_INSIGHT" />,
      type: 'fill',
      url: MARKET_INSIGHTS_URLS.segmentManager,
      appAccessList: [COBALT_APP_TYPE],
      children: [
        {
          name: t(
            'sidebar:marketInsights.child.segmentManager',
            'Segment Manager'
          ),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.SegmentManager,
          url: MARKET_INSIGHTS_URLS.segmentManager,
          appAccessList: [COBALT_APP_TYPE]
        },
        {
          name: t(
            'sidebar:marketInsights.child.shareAndTrends',
            'Share and Trends'
          ),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ShareAndTrends,
          url: MARKET_INSIGHTS_URLS.shareAndTrendsBase,
          appAccessList: [COBALT_APP_TYPE],
          isCurrentPath: currentPath =>
            !!currentPath.startsWith(MARKET_INSIGHTS_URLS.shareAndTrendsBase)
        }
      ]
    },
    {
      name: t('sidebar:productInsights.header', 'Product Insights'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ProductInsights,
      icon: <Icon name="NAV_PROD_INSIGHTS" />,
      type: 'fill',
      appAccessList: [COBALT_APP_TYPE],
      url: PRODUCT_INSIGHTS_URL
    },
    {
      name: t('sidebar:shareOfVoice.header', 'Share of Voice'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ShareOfVoice,
      icon: <Icon name="NAV_SHARE_VOICE" />,
      type: 'fill',
      appAccessList: [COBALT_APP_TYPE],
      url: SHARE_OF_VOICE_URL
    },
    {
      name: t('sidebar:dashboard.header', 'Home Dashboard'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.HomeDashboard,
      icon: <Icon name="NAV_HOME" />,
      type: 'fill',
      url: DASHBOARD_URL
    },
    {
      isNew: renderBadge('2025-01-01', t('generic:NewTag', 'NEW')),
      name: pageNames.competitiveIntelligence,
      icon: <Icon name="NAV_CI" />,
      type: 'fill',
      url: COMPETITIVE_INTELLIGENCE_URL,
      appAccessList: [DEFAULT_APP_TYPE],
      features: [Features.COMPETITIVE_INTELLIGENCE_MVP],
      permissionKey: PERMISSION_KEYS.COMPETITIVE_INTELLIGENCE
    },
    {
      name: t('sidebar:finder.Header', 'Product Research'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ProductResearch,
      icon: <Icon name="NAV_PROD_RESEARCH" />,
      type: 'fill',
      url: PRODUCT_RESEARCH_URLS.database,
      appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
      children: [
        {
          name: t('sidebar:finder.child.db', 'Product Database'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ProductDatabase,
          url: PRODUCT_RESEARCH_URLS.database,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.FIND_PRODUCTS
        },
        {
          name: t('sidebar:finder.child.tracker', 'Product Tracker'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ProductTracker,
          url: PRODUCT_RESEARCH_URLS.tracker,
          permissionKey: PERMISSION_KEYS.FIND_PRODUCTS
        },
        {
          name: t('sidebar:finder.child.opfinder', 'Opportunity Finder'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.OpportunityFinder,
          url: PRODUCT_RESEARCH_URLS.opportunityFinder,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.FIND_PRODUCTS
        },
        {
          name: t('sidebar:finder.child.categoryTrends', 'Category Trends'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.CategoryTrends,
          url: PRODUCT_RESEARCH_URLS.categoryTrends
        },
        {
          name: t('sidebar:toolbox.child.salesEstimator', 'Sales Estimator'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.SalesEstimator,
          url: SALES_ESTIMATOR_URL,
          appAccessList: [DEFAULT_APP_TYPE, COBALT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.FIND_PRODUCTS
        }
      ],
      permissionKey: PERMISSION_KEYS.FIND_PRODUCTS
    },
    {
      isNew: isNewShareOfVoice,
      name: t('sidebar:keyword.header', 'Keywords'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.Keywords,
      icon: <Icon name="NAV_KEYWORDS" />,
      url: KEYWORD_SCOUT_URL,
      appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
      children: [
        {
          name: t('sidebar:keyword.child.scout', 'Keyword Scout'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.KeywordScout,
          url: KEYWORD_SCOUT_URL,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.KEYWORDS
        },
        {
          name: t('sidebar:keyword.child.list', 'Keyword Lists'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.KeywordLists,
          url: KEYWORD_LISTS_URL,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.KEYWORDS,
          isCurrentPath: currentPath =>
            !!currentPath.startsWith(KEYWORD_LISTS_URL)
        },
        {
          name: t('sidebar:keyword.child.rank', 'Rank Tracker'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.RankTracker,
          url: RANK_TRACKER_V2_BASE,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.KEYWORDS
        },
        {
          name: t('sidebar:keyword.child.builder', 'Listing Builder'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ListingBuilder,
          url: LISTING_BUILDER_BASE,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.KEYWORDS
        },
        {
          name: t('sidebar:toolbox.child.listingGrader', 'Listing Grader'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ListingGrader,
          url: LISTING_GRADER_BASE,
          appAccessList: [DEFAULT_APP_TYPE, COBALT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.KEYWORDS
        },
        // Share of Voice won't work if the user doesn't have allowed marketplaces, even if they have the permission & flag
        ...(featureLimits?.allowedMarketplaces?.length
          ? [
              {
                isNew: isNewShareOfVoice,
                name: pageNames.shareOfVoice,
                dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ShareOfVoice,
                icon: <Icon name="NAV_SHARE_VOICE" />,
                type: 'fill',
                features: [Features.KEYWORDS_SHARE_OF_VOICE],
                appAccessList: [DEFAULT_APP_TYPE],
                url: SHARE_OF_VOICE_URL,
                permissionKey: PERMISSION_KEYS.SHARE_OF_VOICE
              }
            ]
          : [])
      ],
      permissionKey: PERMISSION_KEYS.KEYWORDS
    },
    {
      // TODO: move to mail once 'v2' is released
      name: t('sidebar:prodSetting.header', 'Manage Products'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ManageProducts,
      icon: <Icon name="NAV_MANAGE_PROD" />,
      url: PRODUCT_COSTS_AND_SETTINGS_URL,
      appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
      children: [
        {
          name: t('sidebar:inventory.manager', 'Inventory Manager'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.InventoryManager,
          url: INVENTORY_URLS.inventoryForecast,
          type: 'fill',
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.MY_PRODUCTS
        },
        {
          name: pageNames.costsAndSettings,
          url: PRODUCT_COSTS_AND_SETTINGS_URL,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.MY_PRODUCTS
        }
      ],
      permissionKey: PERMISSION_KEYS.MY_PRODUCTS
    },
    {
      name: t('sidebar:launch.header', 'Marketing'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.Marketing,
      icon: <Icon name="NAV_MARKETING" />,
      url: LAUNCH_ROOT_URL,
      appAccessList: [DEFAULT_APP_TYPE],
      type: 'fill',
      children: [
        {
          name: t(
            'sidebar:launch.child.review_automation',
            'Review Automation'
          ),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ReviewAutomation,
          url: LAUNCH_BASE_URLS.reviewAutomation,
          permissionKey: PERMISSION_KEYS.LAUNCH,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE]
        }
      ],
      permissionKey: PERMISSION_KEYS.LAUNCH
    },
    {
      name: t('sidebar:launch.header', 'Marketing'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.Marketing,
      icon: <Icon name="NAV_MARKETING" />,
      url: LAUNCH_ROOT_URL,
      appAccessList: [COBALT_APP_TYPE],
      type: 'fill',
      children: [
        {
          name: t(
            'sidebar:launch.child.review_automation',
            'Review Automation'
          ),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ReviewAutomation,
          url: LAUNCH_BASE_URLS.reviewAutomation,
          permissionKey: PERMISSION_KEYS.LAUNCH,
          appAccessList: [COBALT_APP_TYPE]
        }
      ],
      permissionKey: PERMISSION_KEYS.LAUNCH
    },
    {
      name: t('sidebar:analytics.header', 'Sales Analytics'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.SalesAnalytics,
      icon: <Icon name="NAV_SALES_ANALYTICS" />,
      url: SALES_ANALYTICS_URL,
      type: 'fill',
      appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
      children: [
        {
          name: t('sidebar:analytics.child.profit', 'Profit Overview'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ProfitOverview,
          url: SALES_ANALYTICS_URLS.profitOverview,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.SALES_ANALYTICS
        },
        {
          name: t('sidebar:analytics.child.profitAndLoss', 'P&L Statement'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.PAndLStatement,
          url: SALES_ANALYTICS_URLS.profitAndLoss,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.SALES_ANALYTICS
        },
        {
          name: t('sidebar:analytics.child.expenses', 'Other Transactions'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.OtherTransactions,
          url: SALES_ANALYTICS_URLS.expenses,
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          permissionKey: PERMISSION_KEYS.SALES_ANALYTICS
        }
      ],
      permissionKey: PERMISSION_KEYS.SALES_ANALYTICS
    },
    {
      name: t('sidebar:advertising.header', 'Advertising'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.Advertising,
      icon: <Icon name="NAV_ADS" />,
      url: ADVERTISING_URL,
      type: 'fill',
      appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
      children: [
        {
          appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.AdsAnalytics,
          name: t('sidebar:advertising.child.analytics', 'Ads Analytics'),
          permissionKey: PERMISSION_KEYS.ADVERTISING,
          url: ADVERTISING_URLS.analytics
        }
      ],
      permissionKey: PERMISSION_KEYS.ADVERTISING
    },
    {
      name: t('sidebar:supplier.header', 'Suppliers'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.Suppliers,
      icon: <Icon name="NAV_SUPPLIERS" />,
      type: 'fill',
      isNew: renderBadge('2024-08-01', t('generic:NewTag', 'NEW')),
      url: SUPPLIER_URLS.supplier,
      children: [
        {
          name: t('sidebar:supplier.child.db', 'Supplier Database'),
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.SupplierDatabase,
          url: SUPPLIER_URLS.supplier,
          children: [
            { url: SUPPLIER_URLS.supplier },
            { url: SUPPLIER_URLS.results },
            { url: SUPPLIER_URLS.view }
          ],
          permissionKey: PERMISSION_KEYS.SUPPLIERS
        },
        {
          name: pageNames.supplierTracker,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.SupplierTracker,
          url: SUPPLIER_URLS.tracker,
          permissionKey: PERMISSION_KEYS.SUPPLIERS
        }
      ],
      permissionKey: PERMISSION_KEYS.SUPPLIERS
    },
    {
      name: pageNames.fbaReimbursements,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.FBAReimbursements,
      url: INVENTORY_REIMBURSEMENTS_URL,
      features: [Features.INVENTORY_REIMBURSEMENTS],
      icon: <Icon name="NAV_REIMBURSEMENT" />,
      type: 'fill'
    },
    {
      isNew: !isCobalt && renderBadge('2024-07-01', t('generic:NewTag', 'NEW')),
      name: pageNames.developer,
      url: DEVELOPER_URL,
      appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
      icon: <Icon name="NAV_DEVELOPER" />,
      type: 'fill',
      permissionKey: PERMISSION_KEYS.PUBLIC_API
    },
    {
      name: t('sidebar:academyHeader', 'Academy'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.Academy,
      icon: <Icon name="NAV_ACADEMY" />,
      url: ACADEMY_URL,
      type: 'fill',
      separator: true,
      permissionKey: PERMISSION_KEYS.ACADEMY
    },
    {
      name: t('sidebar:extensionHeader', 'The Extension'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.TheExtension,
      icon: <Icon name="NAV_EXTENSION" />,
      url: EXTENSION_URL,
      type: 'fill',
      separator: true
    },
    {
      name: t('sidebar:adminHeader', 'Admin Panel'),
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.AdminPanel,
      icon: <Icon name="NAV_ADMIN_PANEL" />,
      url: ADMIN_URL,
      appAccessList: [COBALT_APP_TYPE, DEFAULT_APP_TYPE],
      separator: true
    }
  ]
}
